import * as React from "react";
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import "../styles/screens/Service.scss";
import service from '../assets/service-pg.svg';
import {Tabs} from "../components/Tabs";

const Service = () => {
  return (
    <Layout>
      <Helmet>
        <title>Разработка уникального дизайна в Тирасполе</title>
      </Helmet>
      <section className="service py-5 d-flex justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h6 className="service_title my-3 animated fadeInUp slow">Услуги</h6>
              <div className="beforeLine animated fadeInUp slow">
                <h1 className="title">Планирование, Создание, Тестирование и Выпуск на рынок</h1>
              </div>
              <p className="content animated fadeInUp slow">
                Обладая обширными знаниями в области управления процессами, наша команда может
                 обогатить общую компетенцию вашей организации.
                 Узнайте больше об услугах, которые мы предлагаем.
              </p>
            </div>
            <div className="col-md-6 overflow-hidden">
              <img src={service} alt="service-pg"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Tabs/>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Service;