import * as React from "react";

export interface IProps {
  key: string,
  title: string,
  content: any
}

const arrTabs : IProps[] = [
  {
    key: 'LP',
    title: 'Landing page - for quickly and successfully starting your business.',
    content: <div>
      <p>Landing page is a special page designed for a specific purpose, for example:</p>
      <ul><li> send e-mail</li><li> subscribe on item</li><li> buy item</li><li> make order</li></ul>
    </div>
  },
  {
    key: 'SPA',
    title: 'Single Page Application - for fast site work site on all your devices',
    content: <p>Single Page Application it is new format sites.</p>
  },
  {
    key: 'eCommerce',
    title: 'E-Commerce - ideal for starting your ethernet business',
    content: <p>Progress does not stand still and nowadays a large share of business has already been or on its way to be part of the global Internet. Now, to open your business, it’s enough to create an online store and start selling both goods and services.</p>
  },
  {
    key: 'mobileApp',
    title: 'Mobile app - more 75% of your potential users use mobile devices',
    content: <p>Every day people use their mobile devices more and more often in their daily life. It raises a growing demand for mobile applications that are more convenient and more functional compared to web-sites</p>
  }
];

export const Tabs: React.FC = () => {
  const [currentTab, setCurrentTab] = React.useState<string>('');
  return (
    <div className="tabs container">
      {arrTabs.map((data, i) => {
        return (
          <div key={i} className={`animated fadeInUp delay-${i+1}s slow tab_item ${arrTabs.length !== i+1 ? 'mb-3  mb-sm-5' : ''} row ${currentTab === data.key ? 'active' : ''}`}>
            <div className="col-md-2 text-center"><span>0{i+1}</span></div>
            <div className="col-md-8 tab_item_content align-items-center flex-row">
              <a onClick={() => setCurrentTab(data.key !== currentTab ? data.key : '')}>{data.title}</a>
              <div className="tab_item_content_container">
                <div>{data.content}</div>
              </div>
              </div>
          </div>
        )
      })}
    </div>
  );
};